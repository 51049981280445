import React, { useEffect, useState, useContext } from "react";
import { FormGroup } from "reactstrap";
import UltimosmovimientosContext from "../../context/ultimosmovimientos/ultimosmovimientosContext";
import AuthContext from "../../context/autenticacion/authContext";
import ModalContext from "../../context/modal/modalContext";
import ModalDetalle from "../../components/ModalDetalle";
import ReactGa from "react-ga";
import "./index.css";
import { Link } from "react-router-dom";
import { montoFormat } from "../../helper/amountFormat";
import Grid from "@mui/material/Grid";

import {
  Button,
  CardContainer,
  NotFoundData,
  Page,
  Select,
} from "../../components";
import moment from "moment";
const UltimosMovimientos = () => {
  const ultimosmovimientosContext = useContext(UltimosmovimientosContext);
  const {
    movimientos,
    mensajeAlerta,
    loadingBtn,
    obtenerUltimosMovimientos,
    limpiarUltimosMovimientos,
    limpiarUltimosMovimientosParcial,
  } = ultimosmovimientosContext;

  const modalContext = useContext(ModalContext);
  const { setMessage } = modalContext;

  const authContext = useContext(AuthContext);
  const { actualizarTimer } = authContext;

  const [listaCuentas, setCuentas] = useState(null);
  const [cuenta, guardarCuenta] = useState(null);
  const [opcionSelect, guardarOpcionSelect] = useState("");

  const [modalInfo, guardarModalInfo] = useState({
    open: false,
    info: null,
  });

  useEffect(() => {
    limpiarUltimosMovimientos();
    window.scrollTo(0, 0);
    return () => {
      limpiarUltimosMovimientos();
      limpiarUltimosMovimientosParcial();
      actualizarTimer();
    };
  }, []);

  useEffect(() => {
    //let fondos = JSON.parse(sessionStorage.getItem("fondos"));
    let fondos = [
      { r_cuenta: "OBL" },
      { r_cuenta: "VSF" },
      { r_cuenta: "VCF" },
    ];
    if (fondos.length > 0) {
      // guardarOpcionSelect(JSON.stringify(fondos[0]));
      // guardarCuenta(fondos[0].r_cuenta);
      setCuentas(fondos);
    }

    //si hay un error mostraremos un mensaje
    if (mensajeAlerta) {
      mostrarMensaje();
    } else {
      ReactGa.pageview(window.location.href);
    }

    // eslint-disable-next-line
  }, [mensajeAlerta]);

  const mostrarMensaje = () => {
    setMessage(mensajeAlerta);
  };

  const handleSelectCuenta = (e) => {
    limpiarUltimosMovimientos();
    guardarOpcionSelect(e.target.value);
    if (e.target.value.length > 0) {
      let item = JSON.parse(e.target.value);
      guardarCuenta(item.r_cuenta);
    } else {
      guardarCuenta(null);
    }
  };

  const consultar = () => {
    let bodyFormData = new FormData();
    let dateIni = moment().subtract(3, "month");
    let dateFin = moment();
    let pe_ini =
      Number((dateIni.month() + 1).toString().length) === 1
        ? dateIni.year() + "0" + (dateIni.month() + 1)
        : dateIni.year() + "" + (dateIni.month() + 1);
    let pe_fin =
      Number((dateFin.month() + 1).toString().length) === 1
        ? dateFin.year() + "0" + (dateFin.month() + 1)
        : dateFin.year() + "" + (dateFin.month() + 1);

    bodyFormData.set("tipo_aporte", cuenta);
    bodyFormData.set("pe_ini", pe_ini);
    bodyFormData.set("pe_fin", pe_fin);
    obtenerUltimosMovimientos(bodyFormData);
  };

  const activarBtn =
    cuenta && !loadingBtn
      ? { className: "", status: false }
      : { className: "disable", status: true };

  const alerta = () => {
    return (
      <NotFoundData
        mensaje={
          <>
            No cuentas con movimientos en los últimos 3 meses. Para mayor
            detalle, puedes consultar tus estados de cuenta
            <Link to="/mi-habitat-digital/afiliado/mis-fondos/estado-cuenta-mensual">
              {" "}
              aquí
            </Link>
          </>
        }
      />
    );
  };

  const openModal = (info) => (e) => {
    e.preventDefault();
    let arrayDetalle = [
      {
        titulo: "Descripción",
        descripcion: info.r_descripcion,
      },
      {
        titulo: "Cargo/Abono",
        descripcion: montoFormat(info.r_monto_soles),
      },
      {
        titulo: "Empleador",
        descripcion: info.r_empleador,
      },
      {
        titulo: "Fondo",
        descripcion: info.r_fondo,
      },
      {
        titulo: "Valor cuota",
        descripcion: info.r_valor_cuota,
      },
      {
        titulo: "Fecha de operación",
        descripcion: info.r_fecha,
      },
      {
        titulo: "Mes de remuneración",
        descripcion: info.r_mes_remun,
      },
    ];
    guardarModalInfo({ ...modalInfo, open: true, info: arrayDetalle });
  };

  const closeModal = () => {
    guardarModalInfo({ open: false, info: null });
  };

  const renderTabla = (movimientos) => (
    <section>
      <section className="table-responsive only-desktop">
        <table className="table">
          <thead>
            <tr>
              <th className="custom-header-table-noa">Fecha de operación</th>
              <th className="custom-header-table-noa">Mes de remuneración</th>
              <th className="custom-header-table-noa">Empleador</th>
              <th className="custom-header-table-noa"> Descripción</th>
              <th className="custom-header-table-noa"> Cargo / Abono</th>
              <th className="custom-header-table-noa">
                <i className="fa fa-info-circle"></i> Valor cuota
              </th>
              <th className="custom-header-table-noa"> Fondo</th>
            </tr>
          </thead>
          <tbody>
            {movimientos.map((item, index) => (
              <tr key={index} className="text-center custom-tr-noa">
                <td>{item.r_fecha}</td>
                <td>{item.r_mes_remun}</td>
                <td>{item.r_empleador}</td>
                <td>{item.r_descripcion}</td>
                <td>{montoFormat(item.r_monto_soles)}</td>
                <td>{item.r_valor_cuota}</td>
                <td>{item.r_fondo}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>
      <section className="mb-container-um">
        {movimientos.map((item, index) => (
          <section
            key={index}
            className="p-2 d-flex justify-content-between mb-list-item"
          >
            <div>
              <p className="mb-0 mb-description">{item.r_descripcion}</p>
              <p className="mb-0">{item.r_fecha}</p>
            </div>
            <div className="text-right">
              <p className="mb-0 mb-monto">{montoFormat(item.r_monto_soles)}</p>
              <p className="mb-0">
                <a href={"/#"} onClick={openModal(item)}>
                  ver detalle
                </a>
              </p>
            </div>
          </section>
        ))}
      </section>
      <p className="my-2">* Últimos 3 meses</p>
      <p className="my-2">
        <i className="fa fa-info-circle"></i>Valor cuota vigente al momento de
        la operación.
      </p>
    </section>
  );

  const ArraySlect = (array) => {
    return array.map((item) => {
      let obj = {};

      obj.value = JSON.stringify(item);
      obj.description =
        item.r_cuenta === "OBL"
          ? "Fondo de Jubilación"
          : item.r_cuenta === "VSF"
            ? "Fondo Libre Habitat"
            : "Aportes Voluntarios con fin Previsional";

      return obj;
    });
  };

  if (mensajeAlerta !== null) return <p></p>;
  if (listaCuentas) {
    return (
      <Page title="Últimos movimientos" showHeader={"mobile"}>
        <CardContainer showHeader={false}>
          <Grid container p={0}>
            <Grid item xs={12} lg={4} pr={1}>
              <Select
                textLabel="Selecciona el fondo de inversión"
                value={opcionSelect}
                handleChange={handleSelectCuenta}
                options={ArraySlect(listaCuentas)}
                firstValueOption="-Selecciona-"
              />
            </Grid>
            <Grid item xs={12} lg={6} pr={1}>
              <FormGroup className="mb-0 form-new">
                <Button disabled={activarBtn.status} onClick={consultar}>
                  <span>Consultar </span>
                  {loadingBtn && (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                </Button>
              </FormGroup>
            </Grid>
            <Grid item xs={12} mt={2}>
              {movimientos && (
                <div className="mb-3">
                  {movimientos.length > 0 ? renderTabla(movimientos) : alerta()}
                </div>
              )}
            </Grid>
          </Grid>
        </CardContainer>

        {modalInfo.open && (
          <ModalDetalle
            dispositivo={"mobile"}
            buttonCloseTop={false}
            info={modalInfo.info}
            titulo={"Detalle del movimiento"}
            closeModal={closeModal}
          />
        )}
      </Page>
    );
  } else {
    return <p>cargando</p>;
  }
};

export default UltimosMovimientos;
